import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchLabelsByOrderID,
  fetchUnnacLabelsByOrderID,
} from "../../utils/requests";
import { LabelResponseType, UnaccLabelResponseType } from "../../utils/types";
import { setUnauthorized } from "./authSlice";

export const getLabelsByOrder = createAsyncThunk(
  "labels/getLabelsByOrder",
  async (
    data: { token: string; id: number },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await fetchLabelsByOrderID(data.token, data.id);
      if (response?.ok) {
        const labels: Array<LabelResponseType> = await response.json();
        return labels;
      } else if (response?.status === 401) {
        localStorage.removeItem("authData");
        dispatch(setUnauthorized(true));
      } else if (response?.status === 404) {
        return [];
      }
    } catch (error) {
      if (error instanceof Error) return rejectWithValue(error.message);
    }
  }
);

export const getUnaccLabelsByOrder = createAsyncThunk(
  "labels/getUnaccLabelsByOrder",
  async (data: { token: string; id: number }, { rejectWithValue }) => {
    try {
      const response = await fetchUnnacLabelsByOrderID(data.token, data.id);
      if (response?.ok) {
        const labels: Array<UnaccLabelResponseType> = await response.json();
        return labels;
      } else if (response?.status === 404) {
        return [];
      }
    } catch (error) {
      if (error instanceof Error) return rejectWithValue(error.message);
    }
  }
);

interface IInitialState {
  labels: Array<LabelResponseType>;
  unaccLabels: Array<UnaccLabelResponseType>;
  currentOrderID: number;
  currentOrderName: string;
  isLoadingLabels: boolean;
  isLoadingUnaccLabels: boolean;
}

const initialState: IInitialState = {
  labels: [],
  unaccLabels: [],
  currentOrderID: 0,
  currentOrderName: "",
  isLoadingLabels: false,
  isLoadingUnaccLabels: false,
};

const labelsSlice = createSlice({
  name: "labels",
  initialState,
  reducers: {
    addLabel(state, action: PayloadAction<LabelResponseType>) {
      state.labels.push(action.payload);
    },
    setLabels(state, action: PayloadAction<Array<LabelResponseType>>) {
      state.labels = action.payload;
    },
    setUnacLabels(state, action: PayloadAction<Array<UnaccLabelResponseType>>) {
      state.unaccLabels = action.payload;
    },
    setCurrentOrderID(state, action: PayloadAction<number>) {
      state.currentOrderID = action.payload;
    },
    setCurrentOrderName(state, action: PayloadAction<string>) {
      state.currentOrderName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLabelsByOrder.fulfilled, (state, { payload }) => {
      if (payload) state.labels = payload;
      state.isLoadingLabels = false;
    });
    builder.addCase(getLabelsByOrder.pending, (state) => {
      state.isLoadingLabels = true;
    });
    builder.addCase(getLabelsByOrder.rejected, (state) => {
      state.isLoadingLabels = false;
    });
    builder.addCase(getUnaccLabelsByOrder.fulfilled, (state, { payload }) => {
      if (payload) state.unaccLabels = payload;
      state.isLoadingUnaccLabels = false;
    });
    builder.addCase(getUnaccLabelsByOrder.pending, (state) => {
      state.isLoadingUnaccLabels = true;
    });
    builder.addCase(getUnaccLabelsByOrder.rejected, (state) => {
      state.isLoadingUnaccLabels = false;
    });
  },
});

export const {
  addLabel,
  setLabels,
  setUnacLabels,
  setCurrentOrderID,
  setCurrentOrderName,
} = labelsSlice.actions;

export default labelsSlice.reducer;
