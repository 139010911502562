import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchClients } from "../../utils/requests";
import { AuthResponseType, ClientResponseType } from "../../utils/types";
import { setUnauthorized } from "./authSlice";

export const getClientsData = createAsyncThunk(
  "clients/getClientsData",
  async (authData: AuthResponseType, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchClients(authData.token);
      if (response.ok) {
        const clients: Array<ClientResponseType> = await response.json();
        return clients;
      } else if (response.status === 401) {
        localStorage.removeItem("authData");
        dispatch(setUnauthorized(true));
      }
    } catch (error) {
      if (error instanceof Error) return rejectWithValue(error.message);
    }
  }
);

interface IInitialState {
  clients: Array<ClientResponseType>;
  isLoading: boolean;
  clientsError: boolean;
}

const initialState: IInitialState = {
  clients: [],
  isLoading: false,
  clientsError: false,
};

const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    addClient(state, action: PayloadAction<ClientResponseType>) {
      state.clients.push(action.payload);
    },
    setClients(state, action: PayloadAction<Array<ClientResponseType>>) {
      state.clients = action.payload;
    },
    setClientsError(state, action: PayloadAction<boolean>) {
      state.clientsError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClientsData.fulfilled, (state, { payload }) => {
      if (payload) state.clients = payload;
      state.isLoading = false;
    });
    builder.addCase(getClientsData.pending, (state) => {
      state.clientsError = false;
      state.isLoading = true;
    });
    builder.addCase(getClientsData.rejected, (state) => {
      state.isLoading = false;
      state.clientsError = true;
    });
  },
});

export const { addClient, setClients, setClientsError } = clientsSlice.actions;

export default clientsSlice.reducer;
