import React from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import style from "./ScanBarcode.module.scss";

import LabelPrint from "../Labels/LabelPrint";
import { testLabel } from "../../utils/helpers";
import { LabelResponseType } from "../../utils/types";
import { setUnauthorized } from "../../redux/slices/authSlice";
import { svgScanBarcode } from "../../utils/svgBoxes";
import {
  fetchAddUnacLabelsToOrder,
  fetchLabelPrint,
} from "../../utils/requests";
import WarningLabelNotFound from "./WarningLabelNotFound";
import { useMount } from "../../utils/hooks";
import InfoMessage from "../InfoMessage";

interface IScanBarcode {
  scanResult: string;
  imageUrl: string;
  isImageGet: boolean;
  order_id: number | undefined;
  componentRef: React.RefObject<HTMLDivElement>;
  setShowScan: (val: boolean) => void;
  setIsImageGet: (val: boolean) => void;
  onStartPrintingHandler: () => Promise<void>;
  setScanResult: (val: string) => void;
}

const ScanBarcode: React.FC<IScanBarcode> = (props) => {
  const authData = useAppSelector((state) => state.auth.authData);
  const [responseLabel, setResponseLabel] = React.useState<LabelResponseType>();

  const [isWarning, setWarning] = React.useState(false);
  const [isLabelMounted, setLabelMounted] = React.useState(false);
  const [isUnacLabelAddedMsg, setIsUnacLabelAddedMsg] = React.useState(false);
  const mounted = useMount(isUnacLabelAddedMsg, 300);

  const isPrinted = React.useRef(false);

  const dispatch = useAppDispatch();

  const addUnacLabelToOrderHandler = async () => {
    if (!props.order_id) return;
    try {
      const response = await fetchAddUnacLabelsToOrder(
        props.scanResult,
        props.order_id,
        authData.token
      );
      if (response.ok) {
        setWarning(false);
        setIsUnacLabelAddedMsg(true);
        props.setScanResult("");
      } else if (response?.status === 401) {
        localStorage.removeItem("authData");
        dispatch(setUnauthorized(true));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onChangeBarcodeHandler = async (value: string) => {
    if (!props.order_id) return;
    if (value === "" || !isNaN(+value)) props.setScanResult(value);
  };

  const onKeyDownBarcodeHandler = async (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key !== "Enter" || !props.order_id) {
      return;
    }

    isPrinted.current = false;
    props.setIsImageGet(false);
    try {
      const response = await fetchLabelPrint(
        props.scanResult,
        props.order_id,
        authData.token
      );
      if (response.ok) {
        const label: LabelResponseType = await response.json();
        setResponseLabel(label);
      } else if (response.status === 401) {
        localStorage.removeItem("authData");
        dispatch(setUnauthorized(true));
      } else if (response.status === 404) {
        setResponseLabel(undefined);
        setWarning(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onClickShowScanClosed = () => {
    props.setShowScan(false);
    props.setIsImageGet(false);
  };

  React.useEffect(() => {
    if (!props.order_id) {
      props.setScanResult(testLabel.barcode);
      setResponseLabel(testLabel);
      const handler = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
          props.onStartPrintingHandler();
        }
      };

      document.addEventListener("keydown", handler);

      return () => {
        document.removeEventListener("keydown", handler);
      };
    }
  }, [props]);

  React.useEffect(() => {
    if (!isPrinted.current && responseLabel && isLabelMounted) {
      props.onStartPrintingHandler();
      isPrinted.current = true;
    }
  }, [responseLabel, isLabelMounted, props]);

  return (
    <div className={style.main}>
      <div className={style.window}>
        <div className={style.title}>{svgScanBarcode("white")}SCANNING</div>
        <input
          className={style.barcode}
          type="text"
          value={props.scanResult}
          onChange={(e) => onChangeBarcodeHandler(e.target.value)}
          onKeyDown={(e) => onKeyDownBarcodeHandler(e)}
        />
        <div className={style.showArea}>
          {props.isImageGet ? (
            <img src={props.imageUrl} alt="card" className={style.imageLabel} />
          ) : (
            <>
              <div className={style.content} ref={props.componentRef}>
                <LabelPrint
                  label={responseLabel}
                  setLabelMounted={setLabelMounted}
                />
              </div>
              <div className={style.cover}></div>
            </>
          )}
        </div>
        <div onClick={onClickShowScanClosed} className={style.close}>
          <span className={style.one}></span>
          <span className={style.two}></span>
        </div>
      </div>
      {isWarning && (
        <WarningLabelNotFound
          scanResult={props.scanResult}
          setWarning={setWarning}
          addUnacLabelToOrderHandler={addUnacLabelToOrderHandler}
        />
      )}
      {mounted && (
        <InfoMessage
          isError={false}
          opened={isUnacLabelAddedMsg}
          text={"Unaccounted label added successfully"}
          closeMessage={setIsUnacLabelAddedMsg}
        />
      )}
    </div>
  );
};

export default ScanBarcode;
