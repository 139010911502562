import {
  LabelsSendType,
  LoginType,
  NewClientType,
  NewOrderType,
} from "./types";

const HOSTNAME = "http://209.38.41.140:6060/v1/api";

const OPTIONS: RequestInit = {
  headers: { "Content-Type": "application/json" },
  credentials: "include",
};

const bearerOptions = (token: string) => {
  return {
    ...OPTIONS,
    headers: { ...OPTIONS.headers, Bearer: token },
  };
};

export const fetchAuth = async (sendData: LoginType) => {
  return await fetch(HOSTNAME + "/authorization", {
    ...OPTIONS,
    method: "POST",
    body: JSON.stringify(sendData),
  });
};

export const fetchClients = async (token: string) => {
  return fetch(HOSTNAME + "/clients", { ...bearerOptions(token) });
};

export const fetchOrders = async (token: string) => {
  return fetch(HOSTNAME + "/orders", { ...bearerOptions(token) });
};

export const fetchLabelsByOrderID = async (token: string, id: number) => {
  return fetch(HOSTNAME + `/orders/${id}/labels`, {
    ...bearerOptions(token),
  });
};

export const fetchUnnacLabelsByOrderID = async (token: string, id: number) => {
  return fetch(HOSTNAME + `/orders/${id}/unaccountedLabels`, {
    ...bearerOptions(token),
  });
};

export const fetchDownloadXlsx = async (id: number, token: string) => {
  return fetch(HOSTNAME + `/orders/${id}/excel`, {
    ...bearerOptions(token),
  });
};

export const fetchAddClient = async (
  sendData: NewClientType,
  token: string
) => {
  return fetch(HOSTNAME + "/clients", {
    ...bearerOptions(token),
    method: "POST",
    body: JSON.stringify(sendData),
  });
};

export const fetchAddOrder = async (
  sendData: NewOrderType,
  id: number,
  token: string
) => {
  return fetch(HOSTNAME + `/orders/client/${id}`, {
    ...bearerOptions(token),
    method: "POST",
    body: JSON.stringify(sendData),
  });
};

export const fetchChangeOrderStatus = async (
  sendData: string,
  id: number,
  token: string
) => {
  return fetch(HOSTNAME + `/orders/${id}/status`, {
    ...bearerOptions(token),
    method: "PATCH",
    body: JSON.stringify({ status: sendData }),
  });
};

export const fetchChangeLabelStatus = async (id: number, token: string) => {
  return fetch(HOSTNAME + `/labels/${id}/reset`, {
    ...bearerOptions(token),
    method: "POST",
  });
};

export const fetchDeleteUnacLabel = async (id: number, token: string) => {
  return fetch(HOSTNAME + `/unaccountedLabel/${id}`, {
    ...bearerOptions(token),
    method: "DELETE",
  });
};

export const fetchLabelPrint = async (
  sendData: string,
  id: number,
  token: string
) => {
  return fetch(HOSTNAME + `/orders/${id}/print`, {
    ...bearerOptions(token),
    method: "POST",
    body: JSON.stringify({ barcode: sendData }),
  });
};

export const fetchAddLabelsToOrder = async (
  sendData: LabelsSendType[],
  id: number,
  token: string
) => {
  return fetch(HOSTNAME + `/orders/${id}/labels`, {
    ...bearerOptions(token),
    method: "POST",
    body: JSON.stringify({ labels: sendData }),
  });
};

export const fetchAddUnacLabelsToOrder = async (
  sendData: string,
  id: number,
  token: string
) => {
  return fetch(HOSTNAME + `/orders/${id}/unaccountedLabels`, {
    ...bearerOptions(token),
    method: "POST",
    body: JSON.stringify({ barcode: sendData }),
  });
};

export const fetchLic = async () => {
  return fetch(HOSTNAME + "/jspm?timestamp=" + Date.now(), { ...OPTIONS });
};
