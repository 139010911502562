import { useAppSelector } from "../redux/hooks";
import { FC, Fragment, useEffect, useState } from "react";

import style from "./Orders.module.scss";

import Sort from "../components/Sort";
import MainTitle from "../components/MainTitle";
import InfoMessage from "../components/InfoMessage";
import NewOrder from "../components/Orders/NewOrder";
import OrderItem from "../components/Orders/OrderItem";
import OrderDetails from "../components/Orders/OrderDetails";
import OrderManagementPanel from "../components/Orders/OrderManagementPanel";
import { useDebounce, useMount } from "../utils/hooks";
import { OrderResponseType } from "../utils/types";
import { DELAY, formatDate, paginate } from "../utils/helpers";
import { setOrders } from "../redux/slices/ordersSlice";

const titles = [
  "№",
  "Client ID",
  "Manager ID",
  "Order name",
  "Date",
  "Status",
  "Labels",
  "",
];

const Orders: FC<{ mainTitle: string }> = ({ mainTitle }) => {
  const { orders, isLoading, ordersError } = useAppSelector(
    (state) => state.orders
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [isNewOrderAddedMsg, setIsNewOrderAddedMsg] = useState(false);
  const [isNewLabelsAddedMsg, setIsNewLabelsAddedMsg] = useState(false);
  const [isAddLabelsError, setIsAddLabelsError] = useState(false);

  const mountedAddorder = useMount(isNewOrderAddedMsg, 300);
  const mountedAddLabels = useMount(isNewLabelsAddedMsg, 300);
  const mountedError = useMount(isAddLabelsError, 300);

  const [localOrders, setLocalOrders] = useState<Array<OrderResponseType>>([]);
  const [activeIndexTitle, setActiveIndexTitle] = useState("");

  const [isDetailsOrderClecked, setDetailsOrderClecked] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<OrderResponseType>();

  const [isAddOrderClicked, setAddOrderClicked] = useState(false);

  const [titleID, setTitleID] = useState("");
  const [titleClientID, setTitleClientID] = useState("");
  const [titleWorkerID, setTitleWorkerID] = useState("");
  const [titleName, setTitleName] = useState("");
  const [titleDate, setTitleDate] = useState("");
  const [titleStatus, setTitleStatus] = useState("");
  const [titleLabel, setTitleLabel] = useState("");

  const debouncedTitleID = useDebounce(titleID, DELAY);
  const debouncedTitleClientID = useDebounce(titleClientID, DELAY);
  const debouncedTitleWorkerID = useDebounce(titleWorkerID, DELAY);
  const debouncedTitleName = useDebounce(titleName, DELAY);
  const debouncedTitleDate = useDebounce(titleDate, DELAY);
  const debouncedTitleStatus = useDebounce(titleStatus, DELAY);
  const debouncedTitleLabel = useDebounce(titleLabel, DELAY);

  useEffect(() => {
    setLocalOrders(paginate(orders, currentPage));
  }, [orders, currentPage]);

  useEffect(() => {
    const result = orders.filter(
      (order) =>
        (!order.id || order.id.toString().includes(debouncedTitleID)) &&
        (!order.client_id ||
          order.client_id.toString().includes(debouncedTitleClientID)) &&
        (!order.worker_id ||
          order.worker_id.toString().includes(debouncedTitleWorkerID)) &&
        (!order.order_name ||
          order.order_name
            .toLowerCase()
            .includes(debouncedTitleName.toLowerCase())) &&
        (!order.created_at ||
          formatDate(order.created_at).includes(debouncedTitleDate)) &&
        (!order.status ||
          order.status
            .toLowerCase()
            .includes(debouncedTitleStatus.toLowerCase())) &&
        (order.labels_count === undefined ||
          order.labels_count === null ||
          order.labels_count.toString().includes(debouncedTitleLabel))
    );

    setLocalOrders(paginate(result, currentPage));
  }, [
    debouncedTitleID,
    debouncedTitleClientID,
    debouncedTitleWorkerID,
    debouncedTitleName,
    debouncedTitleDate,
    debouncedTitleStatus,
    debouncedTitleLabel,
    orders,
    currentPage,
  ]);

  useEffect(() => {
    document.title = "Label Matrix | Orders";
  }, []);

  const titleInput = (
    title: string,
    placeholder: string,
    index: string,
    setFn: (value: string) => void
  ) => {
    return (
      <div className={style.inputBox}>
        <input
          type="text"
          value={title}
          placeholder={placeholder}
          onChange={(e) => {
            setFn(e.target.value);
            setCurrentPage(1);
          }}
        />
        <Sort
          index={index}
          data={orders}
          setData={setOrders}
          activeIndexTitle={activeIndexTitle}
          setActiveIndexTitle={setActiveIndexTitle}
        />
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className={style.loaderBox}>
        <div className={style.loader}></div>
      </div>
    );
  }

  if (ordersError) {
    return <div className={style.loaderBox}>Server connection error!</div>;
  }

  return (
    <>
      {isDetailsOrderClecked ? (
        <OrderDetails
          currentOrder={currentOrder}
          setDetailsOrderClecked={setDetailsOrderClecked}
        />
      ) : (
        <div className={style.main}>
          <MainTitle title={mainTitle} />
          <div className={style.data}>
            <div className={style.dataTitle}>
              {titleInput(titleID, titles[0], "id", setTitleID)}
              {titleInput(
                titleClientID,
                titles[1],
                "client_id",
                setTitleClientID
              )}
              {titleInput(
                titleWorkerID,
                titles[2],
                "worker_id",
                setTitleWorkerID
              )}
              {titleInput(titleName, titles[3], "order_name", setTitleName)}
              {titleInput(titleDate, titles[4], "created_at", setTitleDate)}
              {titleInput(titleStatus, titles[5], "status", setTitleStatus)}
              {titleInput(titleLabel, titles[6], "labels_count", setTitleLabel)}
            </div>

            {localOrders.map((order) => (
              <Fragment key={order.id}>
                <OrderItem
                  order={order}
                  setCurrentOrder={setCurrentOrder}
                  setDetailsOrderClecked={setDetailsOrderClecked}
                  setIsNewLabelsAddedMsg={setIsNewLabelsAddedMsg}
                  setIsAddLabelsError={setIsAddLabelsError}
                />
              </Fragment>
            ))}
          </div>
          <OrderManagementPanel
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            onButtonHandler={setAddOrderClicked}
          />
        </div>
      )}
      {isAddOrderClicked && (
        <NewOrder
          setAddOrderClicked={setAddOrderClicked}
          setIsNewOrderAddedMsg={setIsNewOrderAddedMsg}
          setIsAddLabelsError={setIsAddLabelsError}
        />
      )}
      {mountedAddorder && (
        <InfoMessage
          isError={false}
          opened={isNewOrderAddedMsg}
          text={"New order added successfully"}
          closeMessage={setIsNewOrderAddedMsg}
        />
      )}
      {mountedAddLabels && (
        <InfoMessage
          isError={false}
          opened={isNewLabelsAddedMsg}
          text={"Labels added successfully"}
          closeMessage={setIsNewLabelsAddedMsg}
        />
      )}
      {mountedError && (
        <InfoMessage
          isError={true}
          opened={isAddLabelsError}
          text={"Can't careate new order or add labels"}
          closeMessage={setIsAddLabelsError}
        />
      )}
    </>
  );
};

export default Orders;
