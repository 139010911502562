import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchOrders } from "../../utils/requests";
import { AuthResponseType, OrderResponseType } from "../../utils/types";
import { setUnauthorized } from "./authSlice";

export const getOrdersData = createAsyncThunk(
  "orders/getOrdersData",
  async (authData: AuthResponseType, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchOrders(authData.token);
      if (response?.ok) {
        const orders: Array<OrderResponseType> = await response.json();
        return orders.filter(
          (el) => authData.access_level > 0 || el.status === "PROCESSING"
        );
      } else if (response?.status === 401) {
        localStorage.removeItem("authData");
        dispatch(setUnauthorized(true));
      }
    } catch (error) {
      if (error instanceof Error) return rejectWithValue(error.message);
    }
  }
);

interface IInitialState {
  orders: Array<OrderResponseType>;
  isLoading: boolean;
  ordersError: boolean;
}

const initialState: IInitialState = {
  orders: [],
  isLoading: false,
  ordersError: false,
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    addOrder(state, action: PayloadAction<OrderResponseType>) {
      state.orders.push(action.payload);
    },
    setOrders(state, action: PayloadAction<Array<OrderResponseType>>) {
      state.orders = action.payload;
    },
    setOrdersError(state, action: PayloadAction<boolean>) {
      state.ordersError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrdersData.fulfilled, (state, { payload }) => {
      if (payload) state.orders = payload;
      state.isLoading = false;
    });
    builder.addCase(getOrdersData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOrdersData.rejected, (state) => {
      state.isLoading = false;
      state.ordersError = true;
    });
  },
});

export const { addOrder, setOrders, setOrdersError } = ordersSlice.actions;

export default ordersSlice.reducer;
