import Barcode from "react-barcode";
import { WSStatus } from "jsprintmanager";
import { FC, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../redux/hooks";

import style from "./DeviceTesting.module.scss";

import InfoMessage from "../components/InfoMessage";
import JspmInputs from "../components/JSPM/JspmInputs";
import JspmStatusBox from "../components/JSPM/JspmStatusBox";
import StartPrinting from "../components/JSPM/StartPrinting";
import JspmNotWorking from "../components/JSPM/JspmNotWorking";
import { useMount } from "../utils/hooks";
import { svgPrinterTest, svgScannerTest } from "../utils/svgBoxes";

const DeviceTesting: FC = () => {
  const { isLoading, jspmStatus } = useAppSelector((state) => state.jspm);

  const [isScanerSelected, setScannerSelected] = useState(true);
  const [scanResult, setScanResult] = useState("");
  const [randomBarcode, setRandomBarcode] = useState("");
  const [isTestScanCorrect, setIsTestScanCorrect] = useState(false);
  const [isTestScanIncorrect, setIsTestScanIncorrect] = useState(false);

  const [selectedPrinter, setSelectedPrinter] = useState("");
  const [selectedPaper, setSelectedPaper] = useState("");
  const [selectedLabel, setSelectedLabel] = useState("");
  const [copies, setCopies] = useState("1");

  const inputRef = useRef<HTMLInputElement>(null);

  const mountedCorrectScan = useMount(isTestScanCorrect, 300);
  const mountedInCorrectScan = useMount(isTestScanIncorrect, 300);

  useEffect(() => {
    document.title = "Label Matrix | Devices Testing";
    const random = Math.floor(Math.random() * 10000000000000)
      .toString()
      .padEnd(13, "0");
    setRandomBarcode(random);
  }, []);

  useEffect(() => {
    const random = Math.floor(Math.random() * 10000000000000)
      .toString()
      .padEnd(13, "0");
    if (scanResult) {
      if (randomBarcode === scanResult) {
        setIsTestScanCorrect(true);
        setRandomBarcode(random);
        if (inputRef.current) {
          inputRef.current.focus();
          setScanResult("");
        }
      } else {
        setIsTestScanIncorrect(true);
      }
    }
  }, [scanResult]);

  const onChangeInputHandler = (value: string) => {
    if (value === "") setScanResult(value);
    if (!isNaN(+value)) {
      setScanResult(value);
    }
  };

  const scannerTest = () => {
    return (
      <div className={style.scanner}>
        <div className={style.bar}>
          <Barcode
            value={randomBarcode}
            width={6}
            height={150}
            fontSize={48}
            font="Segoe UI, Tahoma, Geneva, Verdana, sans-serif"
          />
        </div>
        <input
          ref={inputRef}
          className={style.result}
          type="text"
          value={scanResult}
          onChange={(e) => onChangeInputHandler(e.target.value)}
        />
      </div>
    );
  };

  const printerTest = () => {
    return (
      <div className={style.printer}>
        <JspmStatusBox />
        <div className={style.inputs}>
          <JspmInputs
            copies={copies}
            setCopies={setCopies}
            selectedLabel={selectedLabel}
            setSelectedLabel={setSelectedLabel}
            selectedPaper={selectedPaper}
            setSelectedPaper={setSelectedPaper}
            selectedPrinter={selectedPrinter}
            setSelectedPrinter={setSelectedPrinter}
          />
        </div>
        <StartPrinting
          order_id={undefined}
          copies={copies}
          selectedPrinter={selectedPrinter}
          selectedPaper={selectedPaper}
          isCheckboxActive={true}
        />
        {isLoading && (
          <div className={style.background}>
            <div>Getting printers info...</div>
            <div className={style.wloader}></div>
          </div>
        )}
      </div>
    );
  };

  const scStyle = `${style.device} ${isScanerSelected && style.activeDevice}`;
  const prStyle = `${style.device} ${!isScanerSelected && style.activeDevice}`;

  if (jspmStatus === WSStatus.Closed) {
    return (
      <div className={style.wrapper}>
        <JspmNotWorking />
      </div>
    );
  } else if (jspmStatus === WSStatus.WaitingForUserResponse) {
    return (
      <div className={style.loaderBox}>
        <div className={style.loader}></div>
      </div>
    );
  }

  return (
    <div className={style.main}>
      <div className={style.devices}>
        <div
          className={scStyle}
          style={{ letterSpacing: "0.05rem" }}
          onClick={() => setScannerSelected(true)}
        >
          {svgScannerTest()} SCANNER TEST
        </div>
        <div className={prStyle} onClick={() => setScannerSelected(false)}>
          {svgPrinterTest()} PRINTER TEST
        </div>
      </div>
      <div className={style.content}>
        {isScanerSelected ? scannerTest() : printerTest()}
      </div>
      {mountedCorrectScan && (
        <InfoMessage
          isError={false}
          opened={isTestScanCorrect}
          text="The scan result is correct"
          closeMessage={setIsTestScanCorrect}
        />
      )}
      {mountedInCorrectScan && (
        <InfoMessage
          isError={true}
          opened={isTestScanIncorrect}
          text="The scan result is incorrect!"
          closeMessage={setIsTestScanIncorrect}
        />
      )}
    </div>
  );
};

export default DeviceTesting;
